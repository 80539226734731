<template>
  <div class="c-preview">
    <img v-if="!showRules" class="o-image" :src="headerImage" alt="Preview" />
    <div class="c-preview__content">
      <h1 class="c-preview__title">{{ copy.title }}</h1>

      <p>
        {{ copy.body }}
      </p>

      <button class="c-button" @click="changeView('game')">{{ copy.button }}</button>
      <button v-if="!showRules" class="c-button c-button--outline" @click="showRules = true">{{ copy.secondaryButton }}</button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { CAMPAIGN_CONFIG as config } from '../config';
const headerImage = config.backgroundImage;

const emit = defineEmits(['changeView'])

const showRules = ref(false)
const copy = computed(() => {
  if (showRules.value) {
    return config.copy.rules
  } else {
    return config.copy.preview
  }
})

const changeView = (newView) => {
  emit('changeView', newView)
}
</script>

<style lang="scss" scoped>
.c-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .o-image {
    padding-bottom: 1rem;
    max-width: 100%;
  }

  &__content {
    width: 100%;
    background-color: var(--background-color);
    position: relative;
    top: -40px;
    border-radius: 20px 20px 0 0;
    padding-top: 4rem;
  }

  .c-button {
    max-width: 90%;
    margin: 5px 20px;
    padding: 0.2rem;
    font-size: 1.5rem;

  }
}
</style>
