<template>
    <div v-if="count > 0" class="countdown">
      <span class="count">{{ count }}</span>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted } from 'vue'
  
  const count = ref(3)
  const emit = defineEmits(['start'])
  
  let countdownInterval
  
  const startCountdown = () => {
    countdownInterval = setInterval(() => {
      if (count.value > 1) {
        count.value--
      } else {
        clearInterval(countdownInterval)
        emit('start')
      }
    }, 1000)
  }
  
  onMounted(() => {
    startCountdown()
  })
  
  onUnmounted(() => {
    clearInterval(countdownInterval)
  })
  </script>
  
  <style scoped>
  .countdown {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 5rem;
    font-weight: bold;
    color: white;
    font-family: var(--font-title);
    text-decoration: uppercase;
    letter-spacing: var(--letter-spacing-title);
  }
  </style>