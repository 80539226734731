import { createApp } from 'vue'
import { Notifier as AirbrakeNotifier } from '@airbrake/browser'
import App from './App.vue'
import './assets/css/index.scss'
import './assets/css/themes.scss' // To make it possible to override styling.
import { observeScreenSize } from './utils/screenSizeObserver'
import { dataLayer, configuration, hybrid, setupAirbrake } from '@dpgradio/creative';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

window.createVueApp = async () => {
  const app = createApp(App).use(hybrid).use(autoAnimatePlugin)

  // GET STATION
  const parameters = new URLSearchParams(window.location.search)
  await configuration.retrieveConfigForStation(parameters.get('station') ?? 'qmusic_be')

  // DATALAYER
  dataLayer.initialize()

  const mode = new URLSearchParams(window.location.search).get('mode') ?? 'inline'
  document.documentElement.classList.add(`${mode}-mode`)

  if (mode === 'inline') {
    observeScreenSize()
  }

  // ERROR REPORTING
  if (import.meta.env.PROD) {
    await setupAirbrake(
      AirbrakeNotifier,
      {
        projectId: 511287,
        projectKey: '307103f3b4060deeb4f72468775fe766',
        environment: 'development',
      },
      app
    )
  }

  app.mount('#verdubbel-je-jaarloon')
}


window.createVueApp()