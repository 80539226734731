<template>
  <div class="c-result">
    <WrongScreen v-if="showWrongScreen" />
    <div class="c-result__content">
      <h1 class="c-result__title">{{ copy.title }}</h1>

      <h2 class="c-result__subtitle" >{{ copy.subtitle(score) }}</h2>
      <h3 class="c-result__score_title">
        {{ copy.score }}
      </h3>
      <span class="c-result__score">{{ props.score }}/{{ numberOfQuestions }}</span>

      <p>{{ copy.body }}</p>

      <button class="c-button" @click="changeView('form')">{{ copy.button }}</button>
      <button class="c-button c-button--outline" @click="changeView('game')">{{ copy.secondaryButton }}</button>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { CAMPAIGN_CONFIG as config } from '../config';
import WrongScreen from './WrongScreen.vue';
const emit = defineEmits(['changeView'])
const props = defineProps({
  score: Number
})

const showWrongScreen = computed(() => props.score < config.numberOfQuestions)

const copy = config.copy.results
const numberOfQuestions = config.numberOfQuestions

const changeView = (newView) => {
  emit('changeView', newView)
}
</script>

<style lang="scss" scoped>
.c-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .o-image {
    padding-bottom: 1rem;
    max-width: 100%;
  }

  &__content {
    width: 100%;
    background-color: var(--background-color);
    position: relative;
    top: -40px;
    border-radius: 20px 20px 0 0;
    padding-top: 4rem;
  }

  &__subtitle {
    font-size: 2rem;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  &__score_title {
    font-size: 1.5rem;
    margin-bottom: -15px;
  }

  &__score {
    font-size: 5rem;
    font-family: var(--font-title);
    letter-spacing: var(--letter-spacing-title);
    font-weight: bold;
  }

  .c-button {
    max-width: 90%;
    margin: 5px 20px;
    padding: 0.2rem;
    font-size: 1.5rem;

  }
}
</style>
