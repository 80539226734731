<template>
  <div class="c-form">
    <div class="form">
      <Question v-for="question in questions" :key="question.id" :question="question" :default-value="question.defaultValue"
      @change="(event) => updateAnswer(question, event.target.value)" />
    </div>

  <span v-if="errors.length">
    <p class="c-error">Gelieve alle velden in te vullen</p>
  </span>
  <button class="c-button" @click="onSave">Registreer</button>
  </div>
  
</template>

<script setup>
import { ref } from 'vue';
import { CAMPAIGN_CONFIG as config } from '../../config';
import Question from './Question.vue';
const questions = config.form.questions;

const answers = ref({})
const errors = ref([])

const updateAnswer = (question, answer) => {
  if (question.type === 'checkbox') {
    answers.value[question.id] = answer ? question.checkboxText : ''
  } else {
    answers.value[question.id] = answer
  }
}

const onSave = () => {
  questions.forEach((question) => {
    if (question.required && !answers.value[question.id]) {
      errors.value.push(question.id)
    }
  })

  if (errors.value.length === 0) {
    submitForm()
  }
}

const submitForm = async () => {
  const response = await fetch(config.form.url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(answers.value),
  })

  if (response.ok) {
    // Success
  } else {
    // Error
  }
}
</script>

<style lang="scss" scoped>
.c-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  padding: 0 20px 20px;
  box-sizing: border-box;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}
</style>