<template>
    <div id="countdown">
        <svg viewBox="0 0 40 40">
            <g transform="translate(20 20) rotate(-90)" stroke-width="4" fill="none" stroke-linecap="round">
                <circle class="circle1" r="18" />
                <circle class="circle2" r="18" />
            </g>
            <text transform="translate(20 29)">{{ timeLeft }}</text>
        </svg>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

const initialTime = 60
const timeLeft = ref(initialTime)
const emit = defineEmits(['ended'])
let timerInterval

const startTimer = () => {
    timerInterval = setInterval(() => {
        if (timeLeft.value > 0) {
            timeLeft.value--
        } else {
            clearInterval(timerInterval)
            emit('ended')
        }
    }, 1000)
}

onMounted(() => {
    startTimer()
})

onUnmounted(() => {
    clearInterval(timerInterval)
})
</script>

<style lang="scss" scoped>
#countdown {
    margin: 10px;
}

svg {
    .circle1 {
        stroke-dasharray: 113px;
        stroke-dashoffset: 0px;
        stroke: var(--background-color);
        animation: countdown 60s linear infinite forwards;
    }

    .circle2 {
        stroke: var(--background-color);
        opacity: .3;
    }

    text {
        font-family: sans-serif;
        fill: var(--background-color);
        font-family: var(--font-title);
        text-transform: uppercase;
        letter-spacing: var(--letter-spacing-title);
        font-weight: bold;
        text-anchor: middle;
        font-size: 26px;
    }
}

@keyframes countdown {
    from {
        stroke-dashoffset: 0px;
    }

    to {
        stroke-dashoffset: 113px;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}
</style>