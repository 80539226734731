<template>
  <div class="c-game">
    <div v-if="!loaded" class="loader">
      <LoaderIcon />
    </div>
    <Countdown v-if="!running" class="c-game__countdown" @start="running = true" />
    <div v-else class="c-game__content">
      <h1 class="c-game__title">{{ copy.title }}</h1>
      
      <div class="c-game__question">
        <Counter v-if="running" class="counter" @ended="ended" />
        <p>{{ currentQuestion?.question }}</p>
      </div>

      <div class="c-game__answers">
        <button v-for="answer in currentQuestion?.answers" :key="answer" class="c-button" @click="selectAnswer(answer)">{{ answer }}</button>
      </div>
     
      <button v-if="skipsLeft > 0" class="c-button c-button--outline" @click="skipQuestion">{{ copy.skip }}</button>

    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import { CAMPAIGN_CONFIG as config } from '../config';
import Counter from './Counter.vue';
import Countdown from './Countdown.vue';
import LoaderIcon from '../assets/icons/loader.svg';

const copy = config.copy.game

const running = ref(false)
const questions = ref([])
const loaded = ref(false)
const currentQuestion = ref(null)
const score = ref(0)
const skipsLeft = ref(config.numberOfSkips)

const emit = defineEmits(['setScore'])

const selectAnswer = (answer) => {
  if (answer === currentQuestion.value.correct_answer) {
    score.value++
    nextQuestion()
  } else {
    emit('setScore', score.value)
  }
}

const nextQuestion = () => {
  if (questions.value.length === 0) {
    emit('setScore', score.value)
  } else {
    currentQuestion.value = questions.value.shift()
  }
}

const skipQuestion = () => {
  questions.value.push(currentQuestion.value)
  skipsLeft.value--
  nextQuestion()
}

onBeforeMount(async () => {
  await fetchQuestions()
})

const ended = () => {
  emit('setScore', score.value)
}

const fetchQuestions = async () => {
  const response = await fetch('https://spreadsheet.radio.dpgmedia.cloud/prod/fetch?tableId=stIIslNS35bXCOpPhw&pageSize=500')

  const data = await response.json()
  const randomIndexes = Array.from({ length: 10 }, () => Math.floor(Math.random() * data.length))

  randomIndexes.forEach((index) => {
    const question = data[index] 
    questions.value.push({
      question: question["Vraag"],
      correct_answer: question["Juist antwoord"],
      answers: [
        question["Antwoord 1"],
        question["Antwoord 2"],
        question["Antwoord 3"],
        question["Antwoord 4"]
      ]
    })
  })
  loaded.value = true
  currentQuestion.value = questions.value.shift()
}
</script>

<style lang="scss" scoped>
.c-game {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  padding: 0 20px 20px;
  box-sizing: border-box;

  &__content {
    width: 100%;
    padding: 0 20px;
  }

  &__title {
    margin-bottom: 30px;
  }

  &__question {
    background-color: var(--background-alternate-color);
    color: var(--text-alternate-color);
    border-radius: 5px;
    padding: 20px 10px 5px 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .counter {
      position: absolute;
      top: -30px;
      width: 40px;
      height: 40px;
      background: var(--background-alternate-color);
      padding: 5px;
      border-radius: 50%;
    }
  }

  &__answers {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0 15px 0;

    .c-button {
      padding: 7px 10px;
      border-radius: 5px;
      font-size: 1rem;
      color: #333;
      font-family: var(--font-body);
      text-transform: initial;
      font-weight: normal;
      text-align: left;
    }
  }

  &__countdown {
    min-height: 400px;
  }
}

.loader {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: calc(100% - 40px);
  min-height: calc(400px - 40px);
  width: 100%;
  z-index: 1;
  fill: white;
  background-color: var(--background-color);

  svg {
    width: 50px;
    height: 50px;
    animation: spin 5s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
