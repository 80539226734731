import headerImage2024BE from './assets/images/be.png'
const campaign = new URLSearchParams(window.location.search).get('campaign') || 'qmusic-be'

const configs = {
  'qmusic-be': {
    backgroundImage: headerImage2024BE,
    title: 'Vincent Ski',
    theme: 'qmusic-theme',
    actionLink: 'https://qmusic.be/embed/win-een-skireis-dankzij-het-vincent-live-sneeuwalarm',
    numberOfQuestions: 10,
    numberOfSkips: 2,
    colors: {
      background: '#ED3624',
      backgroundAlternate: '#FFF',
      text: '#FFF',
      textAlternate: '#333',
      button: '#FFF',
      buttonText: '#ED3624',
    },
    form: {
      id: 13965,
      questions: [
        {
          text: 'Voornaam',
          type: 'text',
          required: true,
          id: 13965,
        },
        {
          text: 'Achternaam',
          type: 'text',
          required: true,
          id: 13966,
        },
        {
          text: 'E-mailadres',
          type: 'email',
          required: true,
          id: 13967,
        },
        {
          text: 'Telefoonnummer',
          type: 'tel',
          required: true,
          id: 13968,
        },
        {
          text: 'Geboortedatum',
          type: 'date',
          required: true,
          id: 13969,
        },
        {
          text: 'Postcode',
          type: 'text',
          required: true,
          id: 13970,
        },
      ],
    },
    copy: {
      title: 'Meld je aan voor het Vincent Live Sneeuwalarm en win een skivakantie!',
      action: 'Lees hier meer over het Vincent Live Sneeuwalarm',
      footer: 'Door mee te spelen ga je akkoord met de actievoorwaarden van het Vincent Live Sneeuwalarm',
      button: 'Doe mee',
      success: {
        title: 'Je bent aangemeld!',
        body: 'Luister naar Vincent Live en wie weet hoor jij jouw naam wel voorbij komen tijdens het Vincent Live Sneeuwalarm!',
      },
      preview: {
        title: 'Verdubbel je loon',
        button: "Let's Quiz",
        secondaryButton: 'speluitleg',
      },
      rules: {
        title: 'Spelregels',
        body: 'Morgen kan je je vanaf 16u opnieuw aanmelden om kans te maken op een skivakantie! ',
        button: "Let's Quiz",
      },
      game: {
        title: 'Verdubbel je jaarloon',
        skip: 'pas'
      },
      results: {
        title: 'Verdubbel je jaarloon',
        subtitle: (score) => {
          if (score <= 4) return "Jammer"
          else return "Proficiat"
        },
        score: "Je scoorde:",
        body: "em Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type",
        button: "Shcrijf je in",
        secondaryButton: "Probeer opnieuw"
      }
    },
  },
}

export default configs
export const CAMPAIGN_NAME = campaign
export const CAMPAIGN_CONFIG = configs[campaign]
