import { hybrid } from '@dpgradio/creative'

export function observeScreenSize() {
  const observer = new ResizeObserver(() => {
    setScreenHeight()
  })

  observer.observe(document.body)

  hybrid.on('didAppear', () => setScreenHeight())
}

function setScreenHeight() {
  const domHeight = Math.max(
    document.body.scrollHeight,
    document.body.clientHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight
  )

  setTimeout(() => {
    // A delay is needed to make sure the DOM has been updated. (unsure if this fixes the issue)
    hybrid.call('changeHeight', { height: domHeight, animated: false })
  }, 100)
}
